<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{
              $t("message.new_m", {
                m: $t("message.user"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            class="demo-ruleForm"
            label-position="top"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  :label="$t('message.name')"
                  class="label_mini"
                  prop="name"
                >
                  <el-input
                    :placeholder="$t('message.name')"
                    v-model="form.name"
                    size="medium"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>

              <!-- end-col -->
              <el-col :offset="5" :span="8"> </el-col>

              <el-col :span="8">
                <el-form-item
                  :label="$t('message.first_name')"
                  class="label_mini"
                >
                  <el-input
                    :placeholder="$t('message.first_name')"
                    v-model="form.first_name"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end-col -->

              <el-col :span="8">
                <el-form-item :label="$t('message.surname')" class="label_mini">
                  <el-input
                    :placeholder="$t('message.surname')"
                    v-model="form.surname"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end-col -->

              <el-col :span="8">
                <el-form-item
                  :label="$t('message.patronymic')"
                  class="label_mini"
                >
                  <el-input
                    :placeholder="$t('message.patronymic')"
                    v-model="form.patronymic"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end-col -->

              <el-col :span="8">
                <crm-phone-input
                    :inputValue="form.phone" 
                    v-model="form.phone"
                    @getPhoneStatus="getPhoneStatus"
                    :form_prop="'phone'"
                    :country_code="form.phone_country_code"
                ></crm-phone-input> 
              </el-col>
              <!-- end-col -->

              <el-col :span="8">
                <el-form-item
                  :label="$t('message.password')"
                  class="label_mini"
                  prop="password"
                >
                  <el-input
                    type="password"
                    :placeholder="$t('message.password')"
                    v-model="form.password"
                    size="medium"
                    autocomplete="off"
                    show-password
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end-col -->

              <el-col :span="8">
                <el-form-item
                  :label="$t('message.email')"
                  prop="email"
                  class="label_mini"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.email"
                    v-model="form.email"
                    :placeholder="$t('message.email')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- end-col -->

              <el-col :span="8">
                <el-form-item
                  prop="role_id"
                  :label="$t('message.role_id')"
                  class="label_mini"
                >
                  <select-role
                    :id="form.role_id"
                    v-model="form.role_id"
                    :size="'medium'"
                    @roleSlug="roleSlug"
                    :placeholder="columns.role_id.title"
                  ></select-role>
                </el-form-item>
              </el-col>
              <!-- end-col -->

              <el-col :span="8">
                <el-form-item
                  :label="$t('message.pin_code')"
                  class="label_mini"
                >
                  <el-input
                    :placeholder="$t('message.pin_code')"
                    v-model="form.pin_code"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end-col -->

              <el-col :span="8">
                <el-form-item
                  :label="$t('message.status')"
                  class="label_mini"
                  prop="status"
                >
                  <select-status
                    :id="form.status"
                    v-model="form.status"
                    :size="'medium'"
                    :placeholder="columns.status.title"
                  >
                  </select-status>
                </el-form-item>
              </el-col>
              <!-- end-col -->

              <el-col :span="8">
                <el-form-item :label="$t('message.filials')" class="label_mini">
                  <select-filials
                    ref="Filials"
                    v-model="form.filials"
                    :filial_ids="form.filials"
                    :size="'medium'"
                    :placeholder="$t('message.filials')"
                  >
                  </select-filials>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item :label="$t('message.client')" class="label_mini">
                  <select-client
                      :size="'large'"
                      class="w-100"
                      :placeholder="$t('message.client')"
                      :create="true"
                      :query="{clientType_id: 1}"
                      :role_slug="selectedRoleSlug ? selectedRoleSlug : 'partner'"
                      :id="form.client_id"
                      v-model="form.client_id"
                      @updateClientCridentials="updatePartnerCridentials"
                    >
                  </select-client>
                </el-form-item>
              </el-col>

              <el-col :span="3">
                <el-form-item cal :label="$t('message.is_super_admin')" class="my-check">
                  <el-checkbox :disabled="form.control_user" v-model="form.is_super_admin"></el-checkbox>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-form-item cal :label="$t('message.control_user')" class="my-check">
                  <el-checkbox :disabled="form.is_super_admin"  v-model="form.control_user"></el-checkbox>
                </el-form-item>
              </el-col>

              <el-col :span="8" v-if="form.control_user">
                <el-form-item :label="$t('message.user_permission_type')" class="label_mini">
                  <select-user-permission-type
                    v-model="form.user_permission_types"
                    :id="form.user_permission_types"
                    :size="'medium'"
                    :placeholder="$t('message.user_permission_type')"
                  >
                  </select-user-permission-type>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item :label="$t('message.sipAccount')" class="label_mini">
                  <select-sip-account
                    v-model="form.sip_account_id"
                    :id="form.sip_account_id"
                    :size="'medium'"
                    :placeholder="$t('message.sipAccount')"
                  >
                  </select-sip-account>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-upload
                  class="avatar-uploader"
                  :show-file-list="false"
                  action="/"
                  :on-change="updateImageList"
                  list-type="picture-card"
                  accept="image/jpeg, image/png"
                  :auto-upload="false"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus"></i>
                </el-upload>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
      <mail-additional :form="form" ref="mailAdditional"></mail-additional>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectStatus from "@/components/inventory/select-status";
import selectFilials from "@/components/multiSelects/multi-select-filials";
import selectRole from "@/components/inventory/select-role";
import selectSipAccount from "@/components/inventory/select-sip-account";
import mailAdditional from "./user-mail-info";
import selectUserPermissionType from "@/components/multiSelects/select-user-permission-type";
import selectClient from "@/components/inventory/select-client";

export default {
  components: {
    selectStatus,
    selectFilials,
    selectRole,
    mailAdditional,
    selectUserPermissionType,
    selectSipAccount,
    selectClient
  },
  mixins: [form, drawer],
  data() {
    return {
      updateImage: [],
      imageUrl: "",
      checked: false,
      is_phone_valid: false,
      selectedRoleSlug: '',
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "users/rules",
      model: "users/model",
      columns: "users/columns",
    }),
  },
  methods: {
    ...mapActions({
      save: "users/store",
      updateUserFilials: "filials/userFilials",
      updateSipAccountInventory: 'sipAccounts/inventory'
    }),
    roleSlug(slug){
      this.selectedRoleSlug = slug;
      this.form.client_id = null;
    },
    afterOpen(){
      this.updateSipAccountInventory();
    },
    updatePartnerCridentials(client){
      this.$set(this.form, 'phone_country_code', client.phone_country_code);
      this.$set(this.form, 'phone', client.phone);
    },
    getPhoneStatus({is_valid, countryCode}){
        this.is_phone_valid = is_valid;
        this.form.phone_country_code = countryCode;
    },
    submit(close = true) {
      if(!this.form.control_user){
        this.form.user_permission_types = [];
      }
      if(this.selectedRoleSlug == 'cabinet'){
        this.form.is_employee = 3;
        this.form.confirmed_rule_id = 1;
      }
      let formData = new FormData();
      for (const key in this.form) {
        if (key != "avatar" && key !== 'user_emails') {
          if (this.form[key]) {
            formData.append(key, this.form[key]);
          } else {
            formData.append(key, "");
          }
        }else if(key === 'user_emails'){
          formData.append(key, JSON.stringify(this.form[key]));
        }
      }
      if (_.size(this.updateImage) > 0) {
        for (const key in this.updateImage) {
          if (this.updateImage.hasOwnProperty(key)) {
            const element = this.updateImage[key];
            formData.append("avatar", element);
          }
        }
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(formData)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              this.updateUserFilials();
              if (close) {
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    updateImageList(file) {
      if(file.size < 5200000){
        this.imageUrl = URL.createObjectURL(file.raw);
        this.updateImage = [];
        this.updateImage.push(file.raw);
      }else{
        this.$message({
            type: "warning",
            message: this.$t('message.file_size_limit_warning', {number: 5})
          });
      }
    },
    afterLeave(){
        this.$refs.mailAdditional.emails = []; 
        this.form = {}
        let value = {
          id: '',
          email: '',
          mail_password: '',
          smtp_server: '',
          smtp_port: '',
          imap_server: '',
          imap_port: '',
          pop3_server: '',
          pop3_port: '',
          connected: false,
        };
        this.$refs.mailAdditional.emails.push(value);
        this.updateImage = [];
        this.imageUrl = "";
    }
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
.my-check{
  display: flex;
  flex-direction: column;
  align-items: start
}
.my-check .el-form-item__content{
  display: block;
  width: 100%;
}
.my-check  .el-checkbox__inner::after{
    height: 12px;
    left: 10px;
    position: absolute;
    top: 3px;
}
.my-check .el-checkbox__inner{
  width: 24px;
  height: 24px;
}
</style>